<template>
  <div>
    <div class="row">
      <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
        <c-table
          ref="gubunTable"
          title="법령별 구분"
          tableId="gubunTable"
          :columnSetting="false"
          :isFullScreen="false"
          :usePaging="false"
          :isExcelDown="false"
          :columns="mstGrid.columns"
          :data="mstGrid.data"
          :merge="mstGrid.merge"
          @innerBtnClicked="innerBtnClicked"
        >
          <!-- 버튼 영역 -->
          <template slot="table-button">
            <q-btn-group outline >
              <c-btn v-if="editable" label="" icon="add" @btnClicked="addRowMst" />
              <c-btn v-if="editable" label="" icon="save" @btnClicked="saveMst" />
            </q-btn-group>
          </template>
          <template v-slot:customArea="{ props }">
            <template>
              <q-chip
                v-if="props.row.editFlag !== 'C'"
                outline square
                color="blue"
                :clickable="true"
                text-color="white"
                style="margin-bottom:4px !important;"
                @click.stop="getDtls(props.row)">
                ▶
              </q-chip>
            </template>
          </template>
        </c-table>
      </div>
      <div class="col-xs-12 col-sm-12 col-md-8 col-lg-8 col-xl-8">
        <c-table
          ref="dtlGrid"
          title="LBLITEMS"
          tableId="dtlGrid"
          :columnSetting="false"
          :isFullScreen="false"
          :isExcelDown="false"
          :columns="dtlGrid.columns"
          :data="dtlGrid.data"
        >
          <!-- selection="multiple"
          rowKey="saiLawRegisterMasterItemId" -->
          <template slot="table-button">
            <q-btn-group outline >
              <c-btn v-if="editable && selectMstRow.saiLawRegisterMasterId" label="LBLADD" icon="add" @btnClicked="addDtl" />
              <!-- <c-btn v-if="editable && selectMstRow.saiLawRegisterMasterId" label="LBLREMOVE" icon="remove" @btnClicked="deleteDtl" /> -->
              <c-btn v-if="editable && selectMstRow.saiLawRegisterMasterId" label="LBLSAVE" icon="save" @btnClicked="saveDtl" />
            </q-btn-group>
          </template>
        </c-table>
      </div>
    </div>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
import { uid } from 'quasar';
import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';
export default {
  name: 'law-register',
  data() {
    return {
      prompt: false,
      hiddenMstCd: '',
      searchParam: {
        mstCd: '',
      },
      mstGrid: {
        merge: [
          { index: 0, colName: 'lawId' },
        ],
        columns: [
          {
            name: 'lawName',
            field: 'lawName',
            label: '법령명',
            align: 'center',
            style: 'width:100px',
            sortable: false,
            innerBtn: true,
            btns: [
              { label: '', icon: 'add', color: 'light-blue', },
            ],
          },
          {
            required: true,
            name: 'gubunName',
            field: 'gubunName',
            label: '구분',
            align: 'left',
            style: 'width:150px',
            type: 'text',
            sortable: false,
          },
          {
            name: 'useFlag',
            field: 'useFlag',
            label: '사용여부',
            align: 'center',
            style: 'width:50px',
            type: 'check',
            true: 'Y',
            false: 'N',
            sortable: false,
          },
          {
            name: 'customCol',
            field: 'customCol',
            label: 'LBLITEMS',
            align: 'center',
            style: 'width:50px',
            type: 'custom',
            sortable: false
          },
        ],
        data: [],
      },
      dtlGrid: {
        columns: [
          {
            required: true,
            name: 'itemName',
            field: 'itemName',
            label: 'LBLITEMS',
            type: 'text',
            align: 'left',
            style: 'width:150px',
            sortable: true,
          },
          {
            required: true,
            name: 'relevantClause',
            field: 'relevantClause',
            label: '관련조항',
            type: 'text',
            align: 'center',
            style: 'width:100px',
            sortable: true,
          },
          {
            name: 'requirements',
            field: 'requirements',
            label: '요구사항',
            type: 'textarea',
            align: 'left',
            sortable: true,
          },
          {
            name: 'applyConstruction',
            field: 'applyConstruction',
            label: '적용사항',
            type: 'text',
            align: 'left',
            style: 'width:140px',
            sortable: true,
          },
          {
            name: 'target',
            field: 'target',
            label: '적용대상',
            type: 'text',
            align: 'center',
            style: 'width:90px',
            sortable: true,
          },
          {
            name: 'relatedDocument',
            field: 'relatedDocument',
            label: '관련문서 (기록)',
            type: 'textarea',
            align: 'left',
            style: 'width:200px',
            sortable: true,
          },
          {
            name: 'useFlag',
            field: 'useFlag',
            label: '사용여부',
            type: 'check',
            sortable: true,
            align: 'center',
            style: 'width:80px',
            true: 'Y',
            false: 'N'
          },
        ],
        data: [],
      },
      selectMstRow: {
        saiLawRegisterMasterId: '',  // 법규등록부 구분 일련번호
        lawId: '',  // 법령ID
        lawName: '',  // 법령ID
        gubunName: '',  // 구분
        useFlag: 'Y',
        regUserId: '',
        chgUserId: '',
        editFlag: '',
      },
      lawRegister: {
        saiLawRegisterMasterItemId: '',  // 법규등록부 항목 일련번호
        saiLawRegisterMasterId: '',  // 법규등록부 구분 일련번호
        itemName: '',  // 항목
        relevantClause: '',  // 관련조항
        requirements: '',  // 요구사항
        applyConstruction: '',  // 적용사항
        target: '',  // 적용대상
        relatedDocument: '',  // 관련문서(기록)
        useFlag: 'Y',  // 사용여부
        regUserId: '',  // 등록자
        chgUserId: '',  // 수정자
      },
      useFlagItems: [
        { code: 'Y', codeName: '사용' },
        { code: 'N', codeName: '미사용' },
      ],
      mappingType: 'POST',
      saveMode: false,
      editable: true,
      isSave: false,
      mstlistUrl: '',
      mstSaveUrl: '',
      dtllistUrl: '',
      dtlDetailUrl: '',
      dtlSaveUrl: '',
      dtlInsertUrl: '',
      popupOptions: {
        isFull: false,
        width: '40%',
        target: null,
        title: '',
        visible: false,
        param: {},
        closeCallback: null,
      },
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  computed: {
    showDetail() {
      return this.selectMstRow 
        && this.selectMstRow.saiLawRegisterMasterId 
        && this.selectMstRow.editFlag !== 'C'
    },
    detailDisabled() {
      return !this.showDetail || !this.saveMode
    }
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      this.mstlistUrl = selectConfig.sai.lawRegister.gubun.list.url;
      this.mstSaveUrl = transactionConfig.sai.lawRegister.gubun.save.url;
      this.dtllistUrl = selectConfig.sai.lawRegister.item.list.url;
      this.dtlDetailUrl = selectConfig.sai.lawRegister.item.get.url;
      this.dtlSaveUrl = transactionConfig.sai.lawRegister.item.insert.url;
      // code setting
      // list setting
      this.getMsts();
    },
    getMsts() {
      this.$http.url = this.mstlistUrl;
      this.$http.type = 'GET';
      this.$http.request((_result) => {
        this.mstGrid.data = _result.data;
      },);
    },
    innerBtnClicked(col, props) {
      this.mstGrid.data.splice(props.rowIndex, 0, {
        saiLawRegisterMasterId: uid(),  // 법규등록부 구분 일련번호
        lawId: props.row.lawId,
        lawName: props.row.lawName,
        gubunName: '',  // 구분
        useFlag: 'Y',  // 사용여부
        editFlag: 'C',
        regUserId: this.$store.getters.user.userId,
      })
    },
    getDtls(row) {
      if (row.editFlag !== 'C') {
        this.$set(this.$data, 'selectMstRow', row)
        this.saveMode = false;
        Object.assign(this.$data.lawRegister, this.$options.data().lawRegister);

        this.$http.url = this.dtllistUrl
        this.$http.type = 'GET';
        this.$http.param = {
          saiLawRegisterMasterId: row.saiLawRegisterMasterId
        };
        this.$http.request((_result) => {
          this.dtlGrid.data = _result.data;
        },);
      }
    },
    reset() {
      this.saveMode = false;
      this.dtlGrid.data = [];
      Object.assign(this.$data.lawRegister, this.$options.data().lawRegister);
    },
    addRowMst() {
      this.reset();
      this.popupOptions.title = '법령';
      this.popupOptions.param = {
        type: 'multiple',
      };
      this.popupOptions.target = () => import(`${'./lawGubunPop.vue'}`);
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closeLawGubunPopup;
    },
    closeLawGubunPopup(data) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      if (data && data.length > 0) {
        this.$_.forEach(data, item => {
          let _idx = this.$_.findIndex(this.mstGrid.data, { lawId: item.code });
          _idx = _idx > -1 ? _idx : 0
          this.mstGrid.data.splice(_idx, 0, {
            saiLawRegisterMasterId: uid(),  // 법규등록부 구분 일련번호
            lawId: item.code,
            lawName: item.codeName,
            gubunName: '',  // 구분
            useFlag: 'Y',  // 사용여부
            editFlag: 'C',
            regUserId: this.$store.getters.user.userId,
          })
        })
      }
    },
    saveMst() {
      if (this.$comm.validTable(this.mstGrid.columns, this.mstGrid.data)) {
        let saveData = this.mstGrid.data.filter( x => {
          return x.editFlag == 'C' || x.editFlag == 'U'
        });
        this.$_.forEach(saveData, item => {
          item.regUserId = this.$store.getters.user.userId;
          item.chgUserId = this.$store.getters.user.userId;
        });
        if(saveData.length > 0) {
          window.getApp.$emit('CONFIRM', {
            title: 'LBLCONFIRM',
            message: 'MSGSAVE', // 저장하시겠습니까?
            type: 'info',
            // 확인 callback 함수
            confirmCallback: () => {
              this.$http.url = this.mstSaveUrl;
              this.$http.type = 'PUT';
              this.$http.param = saveData;
              this.$http.request(() => {
                this.getMsts();
                this.reset();
                window.getApp.$emit('APP_REQUEST_SUCCESS');
              });
            },
            // 취소 callback 함수
            cancelCallback: () => {
            },
          });
        } else {
          window.getApp.$emit('ALERT', {
            title: '안내 ', /* 안내 */
            message: 'MSGNOCHANGEDATA', // 변경된 데이터가 없습니다., /* 변경된 데이터가 없습니다. */
            type: 'info', // success / info / warning / error
          });
        }
      }
    },
    addDtl() {
      this.dtlGrid.data.splice(0, 0, {
        saiLawRegisterMasterItemId: uid(),  // 법규등록부 항목 일련번호
        saiLawRegisterMasterId: this.selectMstRow.saiLawRegisterMasterId,  // 법규등록부 구분 일련번호
        itemName: '',  // 항목
        relevantClause: '',  // 관련조항
        requirements: '',  // 요구사항
        applyConstruction: '',  // 적용사항
        target: '',  // 적용대상
        relatedDocument: '',  // 관련문서(기록)
        useFlag: 'Y',  // 사용여부
        regUserId: '',  // 등록자
        chgUserId: '',  // 수정자
        editFlag: 'C'
      })
    },
    saveDtl() {
      if (this.$comm.validTable(this.dtlGrid.columns, this.dtlGrid.data)) {
        let saveData = this.dtlGrid.data.filter( x => {
          return x.editFlag == 'C' || x.editFlag == 'U'
        });
        this.$_.forEach(saveData, item => {
          item.regUserId = this.$store.getters.user.userId;
          item.chgUserId = this.$store.getters.user.userId;
        });
        if(saveData.length > 0) {
          window.getApp.$emit('CONFIRM', {
            title: 'LBLCONFIRM',
            message: 'MSGSAVE', // 저장하시겠습니까?
            type: 'info',
            // 확인 callback 함수
            confirmCallback: () => {
              this.$http.url = this.dtlSaveUrl;
              this.$http.type = 'POST';
              this.$http.param = saveData;
              this.$http.request(() => {
                this.getDtls(this.selectMstRow)
                window.getApp.$emit('APP_REQUEST_SUCCESS');
              });
            },
            // 취소 callback 함수
            cancelCallback: () => {
            },
          });
        } else {
          window.getApp.$emit('ALERT', {
            title: '안내 ', /* 안내 */
            message: 'MSGNOCHANGEDATA', // 변경된 데이터가 없습니다., /* 변경된 데이터가 없습니다. */
            type: 'info', // success / info / warning / error
          });
        }
      }
    },
    deleteDtl() {
      let selectData = this.$refs['dtlGrid'].selected;
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit('ALERT', {
          title: 'LBLGUIDE', // 안내
          message: 'MSGNOITEMS', // 선택된 항목이 없습니다.
          type: 'warning', // success / info / warning / error
        });
      } else {
        window.getApp.$emit('CONFIRM', {
          title: 'LBLCONFIRM',
          message: 'MSGREMOVE', // 삭제하시겠습니까?,
          type: 'warning', // success / info / warning / error
          // 확인 callback 함수
          confirmCallback: () => {
            this.$http.url = this.$format(this.dtlSaveUrl);
            this.$http.type = 'DELETE';
            this.$http.param = {
              data: Object.values(selectData)
            };
            this.$http.request(() => {
              // this.getList();
              window.getApp.$emit('APP_REQUEST_SUCCESS');
              
              this.$_.forEach(selectData, item => {
                this.dtlGrid.data = this.$_.reject(this.dtlGrid.data, item);
              })
              this.$refs['dtlGrid'].$refs['compo-table'].clearSelection();
            },);
          },
          // 취소 callback 함수
          cancelCallback: () => {
          },
        });
      }
    }
  }
};
</script>
