var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("div", { staticClass: "row" }, [
        _c(
          "div",
          { staticClass: "col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4" },
          [
            _c(
              "c-table",
              {
                ref: "gubunTable",
                attrs: {
                  title: "법령별 구분",
                  tableId: "gubunTable",
                  columnSetting: false,
                  isFullScreen: false,
                  usePaging: false,
                  isExcelDown: false,
                  columns: _vm.mstGrid.columns,
                  data: _vm.mstGrid.data,
                  merge: _vm.mstGrid.merge,
                },
                on: { innerBtnClicked: _vm.innerBtnClicked },
                scopedSlots: _vm._u([
                  {
                    key: "customArea",
                    fn: function ({ props }) {
                      return [
                        [
                          props.row.editFlag !== "C"
                            ? _c(
                                "q-chip",
                                {
                                  staticStyle: {
                                    "margin-bottom": "4px !important",
                                  },
                                  attrs: {
                                    outline: "",
                                    square: "",
                                    color: "blue",
                                    clickable: true,
                                    "text-color": "white",
                                  },
                                  on: {
                                    click: function ($event) {
                                      $event.stopPropagation()
                                      return _vm.getDtls(props.row)
                                    },
                                  },
                                },
                                [_vm._v(" ▶ ")]
                              )
                            : _vm._e(),
                        ],
                      ]
                    },
                  },
                ]),
              },
              [
                _c(
                  "template",
                  { slot: "table-button" },
                  [
                    _c(
                      "q-btn-group",
                      { attrs: { outline: "" } },
                      [
                        _vm.editable
                          ? _c("c-btn", {
                              attrs: { label: "", icon: "add" },
                              on: { btnClicked: _vm.addRowMst },
                            })
                          : _vm._e(),
                        _vm.editable
                          ? _c("c-btn", {
                              attrs: { label: "", icon: "save" },
                              on: { btnClicked: _vm.saveMst },
                            })
                          : _vm._e(),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              2
            ),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "col-xs-12 col-sm-12 col-md-8 col-lg-8 col-xl-8" },
          [
            _c(
              "c-table",
              {
                ref: "dtlGrid",
                attrs: {
                  title: "LBLITEMS",
                  tableId: "dtlGrid",
                  columnSetting: false,
                  isFullScreen: false,
                  isExcelDown: false,
                  columns: _vm.dtlGrid.columns,
                  data: _vm.dtlGrid.data,
                },
              },
              [
                _c(
                  "template",
                  { slot: "table-button" },
                  [
                    _c(
                      "q-btn-group",
                      { attrs: { outline: "" } },
                      [
                        _vm.editable && _vm.selectMstRow.saiLawRegisterMasterId
                          ? _c("c-btn", {
                              attrs: { label: "LBLADD", icon: "add" },
                              on: { btnClicked: _vm.addDtl },
                            })
                          : _vm._e(),
                        _vm.editable && _vm.selectMstRow.saiLawRegisterMasterId
                          ? _c("c-btn", {
                              attrs: { label: "LBLSAVE", icon: "save" },
                              on: { btnClicked: _vm.saveDtl },
                            })
                          : _vm._e(),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              2
            ),
          ],
          1
        ),
      ]),
      _c("c-dialog", { attrs: { param: _vm.popupOptions } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }